<template>
  <v-row
    no-gutters
  >
    <v-col
      cols="4"
    >
      <v-row no-gutters>
        <v-col
          align-self="center"
          sm="4"
        >
          <v-row
            align-self="center"
            class="justify-center"
          >
            <v-icon
              v-for="icon in fetchIcon"
              :key="icon"
              large
              class="mt-1"
              color="color_icon_dark"
            >
              {{ icon }}
            </v-icon>
          </v-row>
        </v-col>
        <v-col align-self="center">
          <span>
            <strong class="mirror-hour">{{ formatDateTimeWithDateWithTimezone(oldEvent.time) }}</strong>
            <br>
            <span>{{ $t(`components.lists.mirrors.${oldEvent.kind}`) }}</span>
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="newEvent"
      sm="2"
      class="mx-2"
      align-self="center"
    >
      <v-text-field
        :label="$t('components.lists.drivers.adjustment.schedule')"
        height="42"
        color="accent"
        :value="formatDateTimeWithDateWithTimezone(newEvent.time)"
        readonly
        outlined
      />
    </v-col>
    <v-col
      v-if="newEvent"
      sm="3"
      class="mx-2"
    >
      <v-select
        :items="[kind]"
        :value="newEvent.kind"
        :label="$t('components.lists.drivers.adjustment.register')"
        color="accent"
        height="42"
        outlined
      />
    </v-col>
  </v-row>
</template>

<script>
import formats from '@/mixins/formats';
import adjustment from '@/mixins/adjustment';

export default {
  name: 'Unedited',
  mixins: [formats, adjustment],
  props: {
    oldEvent: {
      type: Object,
      default: () => ({
        kind: '',
      }),
    },
    newEvent: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
  .mirror-hour {
    color: $color_primary;
  }
</style>
