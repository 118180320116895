<template>
  <v-row
    no-gutters
  >
    <v-col
      cols="4"
    >
      <v-row no-gutters>
        <v-col
          align-self="center"
          sm="4"
        >
          <v-row
            align-self="center"
            class="justify-center"
          >
            <v-icon
              v-for="icon in fetchIcon"
              :key="icon"
              large
              class="mt-1"
              color="color_icon_dark"
            >
              {{ icon }}
            </v-icon>
          </v-row>
        </v-col>
        <v-col>
          <span>
            <strong class="mirror-hour">{{ formatDateTimeWithDateWithTimezone(oldEvent.time) }}</strong>
            <br>
            <span>{{ $t(`components.lists.mirrors.${oldEvent.kind}`) }}</span>
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      sm="2"
      class="mb-6"
    >
      <div class="pa-4 pl-2">
        <strong>{{ $t('components.lists.drivers.adjustment.removed') }}</strong>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import formats from '@/mixins/formats';
import adjustment from '@/mixins/adjustment';

export default {
  name: 'Removed',
  mixins: [formats, adjustment],
  props: {
    oldEvent: {
      type: Object,
      default: () => ({
        kind: '',
      }),
    },
    newEvent: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
  .mirror-hour {
    color: $color_primary;
  }
</style>
