import { render, staticRenderFns } from "./AdjustmentForm.vue?vue&type=template&id=ff4e1cf6&scoped=true&"
import script from "./AdjustmentForm.vue?vue&type=script&lang=js&"
export * from "./AdjustmentForm.vue?vue&type=script&lang=js&"
import style0 from "./AdjustmentForm.vue?vue&type=style&index=0&id=ff4e1cf6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff4e1cf6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VRow,VSelect,VTextarea})
