<template>
  <div>
    <adjustment-form
      v-if="showAdjustmentReasons"
      :workday="getWorkdayUpdate"
      :workday-is-day-off="workdayIsDayOff"
      :day-off="getWorkday.created_at || ''"
      :driver="getDriver"
      :adjustment-reasons="getAdjustmentReasons"
      @approve="doApprove"
      @reject="doReject"
    />
    <div
      v-else
      class="text-center accent--text font-weight-black"
    >
      <v-icon
        color="accent"
        size="25"
      >
        mdi-alert-circle
      </v-icon>
      {{ $t('views.point_mirror_adjustment.not_found') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdjustmentForm from '@/components/forms/AdjustmentForm.vue';

export default {
  name: 'PointMirrorAdjustment',
  components: {
    AdjustmentForm,
  },
  computed: {
    ...mapGetters([
      'getWorkdayUpdate',
      'getAdjustmentReasons',
      'getDriver',
      'getWorkday',
    ]),

    showAdjustmentReasons() {
      return this.getDriver.id != null && this.getWorkday.id != null
        && this.getWorkdayUpdate.id != null && this.getAdjustmentReasons.length > 0;
    },

    workdayIsDayOff() {
      return this.getWorkday.day_off || this.getWorkday.cct_respite;
    },
  },
  watch: {
    getWorkdayUpdate() {
      this.initCurrentBreadcrumbs([
        {
          text: this.$i18n.t('breadcrumbs.point_mirror'),
          disabled: false,
          exact: true,
          to: {
            name: 'mirrorList',
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.driver'),
          disabled: false,
          exact: true,
          to: {
            name: 'mirrorListShow',
            params: {
              id: this.$route.params.userId,
            },
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.details_day'),
          disabled: false,
          exact: true,
          to: {
            name: 'workdayShow',
            params: {
              userId: this.$route.params.userId,
              workdayId: this.getWorkdayId(),
            },
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.adjustment'),
          disabled: true,
        },
      ]);
    },
  },
  mounted() {
    this.fetchWorkday({ id: this.$route.params.workdayId });
    this.fetchWorkdayUpdate({ id: this.$route.params.workdayUpdateId });
    this.fetchStoreDriver({ id: this.$route.params.userId });
    this.fetchAdjustmentReasons();
  },
  methods: {
    ...mapActions([
      'fetchWorkdayUpdate',
      'fetchWorkday',
      'fetchStoreDriver',
      'initCurrentBreadcrumbs',
      'fetchAdjustmentReasons',
      'approve',
      'reject',
    ]),
    doApprove(reason) {
      this.approve({
        id: this.getWorkdayUpdate.id,
        reason_id: reason,
      });
    },
    doReject() {
      this.reject({ id: this.getWorkdayUpdate.id });
    },
    getWorkdayId() {
      if (this.getWorkdayUpdate.status === 'approved') {
        return this.getWorkdayUpdate.new_workday_id;
      }

      return this.getWorkdayUpdate.old_workday_id;
    },
  },
};
</script>
