export default {
  computed: {
    kind() {
      return {
        value: this.newEvent.kind,
        text: this.$t(`components.lists.mirrors.${this.newEvent.kind}`),
      };
    },
    fetchIcon() {
      const kinds = this.oldEvent.kind.split('_');
      if (kinds.length === 1) {
        return [`$vuetify.icons.custom_${kinds[0]}`];
      }
      return [`$vuetify.icons.custom_${kinds[0]}`, `$vuetify.icons.custom_${kinds[1]}`];
    },
  },
  methods: {
    hourFormat(time) {
      return this.formatDateISO(time, 'HH:MM');
    },
  },
};
