<template>
  <v-container
    class="lighten-5 ajustment-form"
  >
    <v-row>
      <v-col class="mb-8">
        <span class="ajustment-title">{{ driver.name }}</span>
        <span class="ajustment-subtitle mx-2">{{ formatAdmissionDate }}</span>
        <br>
        <span class="ajustment-subtitle">{{ driver.cpf }}</span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-row class="ajustment-title">
          <v-col cols="4">
            {{ $t('components.lists.drivers.adjustment.workday') }}
          </v-col>
          <v-col>
            {{ $t('components.lists.drivers.adjustment.changes') }}
          </v-col>
        </v-row>
        <v-row
          v-for="events in workday.merge_workdays"
          :key="events.id"
        >
          <day-off
            v-if="workdayIsDayOff"
            :old-event="events[0]"
            :day-off="dayOff"
          />
          <edited
            v-else-if="ensureWorkday(events) === 'edited'"
            :old-event="events[0]"
            :new-event="events[1]"
          />
          <added
            v-else-if="ensureWorkday(events) === 'added'"
            :new-event="events[0]"
          />
          <removed
            v-else-if="ensureWorkday(events) === 'removed'"
            :old-event="events[0]"
          />
          <unedited
            v-else
            :old-event="events[0]"
            :new-event="events[1]"
          />
        </v-row>
      </v-col>
      <v-col
        sm="4"
        class="mt-13"
      >
        <v-row no-gutters>
          <v-col
            class="mx-2 justify-end"
            align-self="center"
          >
            <v-textarea
              outlined
              readonly
              name="input-7-4"
              :label="$t('components.lists.drivers.adjustment.driver_adjustment_reason')"
              height="120"
              :value="workday.justification"
            />
            <!-- <v-file-input
              :placeholder="$t('components.lists.drivers.adjustment.empty_attachment_file')"
              suffix
            /> -->
            <v-row v-if="isPending">
              <v-col cols="12">
                <v-select
                  v-model="reasonSelect"
                  :items="adjustmentReasons"
                  item-text="name"
                  item-value="id"
                  class="advertence-select"
                  :label="$t('components.lists.drivers.adjustment.reason')"
                  outlined
                />
              </v-col>
              <v-col
                md="12"
                lg="6"
              >
                <v-btn
                  color="accent"
                  class="button pa-5"
                  @click="doApprove()"
                >
                  {{ $t('components.lists.drivers.adjustment.approve') }}
                </v-btn>
              </v-col>
              <v-col
                md="12"
                lg="6"
              >
                <v-btn
                  outlined
                  color="accent"
                  class="button pa-5"
                  @click="doReject()"
                >
                  {{ $t('components.lists.drivers.adjustment.reject') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col v-if="workday.status">
                <p>
                  {{ $t(`components.lists.drivers.adjustment.adjustment_solicitation`) }}
                  <strong>
                    {{ $t(`components.lists.drivers.adjustment.status.${workday.status}`) }}
                  </strong>
                </p>
                <p v-if="workday.adjustment_reason">
                  {{ $t('components.lists.drivers.adjustment.reason') }}
                  <strong>
                    {{ workday.adjustment_reason.name }}
                  </strong>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import Unedited from '@/components/forms/adjustment/Unedited.vue';
import Edited from '@/components/forms/adjustment/Edited.vue';
import Added from '@/components/forms/adjustment/Added.vue';
import Removed from '@/components/forms/adjustment/Removed.vue';
import DayOff from '@/components/forms/adjustment/DayOff.vue';

export default {
  name: 'AdjustmentForm',
  components: {
    Unedited,
    Edited,
    Added,
    DayOff,
    Removed,
  },
  mixins: [formats],
  props: {
    workday: {
      type: Object,
      default: () => {},
    },
    workdayIsDayOff: {
      type: Boolean,
      default: false,
    },
    dayOff: {
      type: String,
      default: '',
    },
    driver: {
      type: Object,
      default: () => {},
    },
    adjustmentReasons: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    reasonSelect: '',
  }),
  computed: {
    formatAdmissionDate() {
      return this.formatDateISO(this.driver.admission_date);
    },
    isPending() {
      return this.workday.status === 'pending';
    },
  },
  methods: {
    ensureWorkday(events) {
      if (events.length === 2) {
        if (!events[0].is_edited && !events[1].is_edited) {
          return 'unedited';
        }

        return 'edited';
      }
      if (events.length === 1) {
        if (events[0].is_edited) {
          return 'added';
        }

        return 'removed';
      }
      return '';
    },
    doApprove() {
      if (!this.reasonSelect) {
        this.$store.dispatch('addErrorMessage', this.$t('errors.adjustment_reason_blank'));
      } else {
        this.$emit('approve', this.reasonSelect);
      }
    },
    doReject() {
      this.$emit('reject');
    },
  },
};
</script>

<style lang="scss" scoped>
.ajustment-form {
  .ajustment-title {
    font-family: 'Fira-Sans-Bold';
    font-size: 20px;
    color: $color_list_header;
  }
  .ajustment-subtitle {
    font-family: 'Acumin-Pro-Regular';
    color: $color_secondary_dark;
  }
  .button {
    font-family: 'Fira-Sans-Bold';
    letter-spacing: 0;
    height: 42px;
    width: 100%;
    min-width: 160px;
  }
  .mirror-hour {
    color: $color_primary;
  }
}
</style>
